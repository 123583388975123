import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { 
  CssBaseline, 
  Typography,
  Collapse,
  Grid,
  useTheme,
  Box
} from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  root: {
    minHeight: '100vh',
    backgroundColor: '#37517e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }, 
  h1:{
    margin: '0 0 10px 0',
    fontSize: '48px',
    fontWeight: '700',
    lineHeight: '56px',
    color: '#fff',
    fontFamily: '"Jost", sans-serif;',
  },
  h2:{
    color: 'rgba(255, 255, 255, 0.6)',
    marginBottom: '50px',
    fontSize: '24px',
    fontFamily: '"Jost", sans-serif;',
  },
  jumbotron: {
    minHeight: '100%',
    width: '100%',
    maxWidth: '100%',
    padding: '3rem 20%',
    textAlign: 'center',
  },
  homeBackground: {
    height: '100%',
    width: '100%',
    minHeight: '100%',
    backgroundColor: 'rgba(255, 255, 255, .15)',
    backdropFilter: 'blur(5px)',
    display: 'block',
  },
  image:{
    width: 'auto',
    height: 'auto'
  },
}));

const Home = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true)
  }, [checked])

  return ( 
    <>  
    <div id='home'>
      <div style={{minHeight: '100vh'}}>
        <div className={classes.homeBackground}></div>
        <div className={classes.root}>
          <CssBaseline/>
            <Collapse
              in={checked}
              {...(checked?{timeout: 700}: {})}
            >
              <Box mt={{xs: '4rem', sm: '4rem'}} p={0}>
                <Grid 
                  container 
                  alignItems='center'
                  zeroMinWidth
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Box px={2} display='flex' flexDirection='column' justifyContent='center'>
                      <h1 className={classes.h1}>Comunicaciones unificadas</h1>
                      <h2 className={classes.h2}>Las mejores soluciones para tu empresa</h2>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display='flex' justifyContent='center'>
                      <img className={classes.image} src={process.env.PUBLIC_URL + '/img/Trii.svg'} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
        </div>
      </div>
    </div>
    </>  
  );
}
 
export default Home;