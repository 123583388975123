import React, {useState, useLocation} from 'react';
import {Link} from 'react-router-dom'
import {animateScroll as scroll} from 'react-scroll'
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme=>({
    drawerList: {
        minWidth: '170px',
    },
    listItem:{
        color: '#000000',
        fontWeight: 'bolder',
        paddingLeft: '33%'
    },
    drawerButtonClose: {
        marginLeft: 'auto'
    },
    closeicon: {
        marginRight: 'auto',
        fontWeight: 'bold',
        color: '#000000',
    }
}));

const DrawerComponent = ({location}) => {
    const classes = useStyles()
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawer=(section)=>{
      if (location === '/'){
        if(section === 'inicio'){
          scroll.scrollTo(0)
        } else if(section === 'nosotros'){
          scroll.scrollTo(document.getElementById('about').offsetTop-60)
        } else if(section === 'modulos'){
          scroll.scrollTo(document.getElementById('modules').offsetTop-60)
        } else if(section === 'funciones'){
          scroll.scrollTo(document.getElementById('services').offsetTop-60)
        } else if(section === 'contacto'){
          scroll.scrollTo(document.getElementById('contact').offsetTop-60)
        }
      setOpenDrawer(false)
      }
    }

    return ( 
      <>  
        <Drawer
          width={330}
          anchor="right"
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
        >
          <IconButton 
            size="lg"
            className={classes.closeicon}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
              <CloseIcon />
          </IconButton>  
          <List className={classes.drawerList}>
              <ListItem
                component ='a'
                href="/#inicio" 
                className={classes.listItem}
                button
                onClick={()=>handleDrawer('inicio')}
              >
                <ListItemText>
                  INICIO
                </ListItemText>
              </ListItem>
              <ListItem 
                component ='a'
                href="/#nosotros" 
                className={classes.listItem} 
                button
                onClick={()=>handleDrawer('nosotros')}
              >
                <ListItemText>
                  NOSOTROS
                </ListItemText>
              </ListItem>
              <ListItem 
                component ='a'
                href="/#modules"
                className={classes.listItem} 
                button
                onClick={()=>handleDrawer('modulos')}
              >
                <ListItemText>
                  MODULOS
                </ListItemText>
              </ListItem>
              <ListItem 
                component ='a'
                href="/#services"
                className={classes.listItem} 
                button
                onClick={()=>handleDrawer('funciones')}
              >
                <ListItemText>
                  FUNCIONES    
                </ListItemText>
              </ListItem>
              <ListItem 
                component ='a'
                href="/#contact"
                className={classes.listItem} 
                button
                onClick={()=>handleDrawer('contacto')}
              >
                <ListItemText>
                    CONTACTO    
                </ListItemText>
              </ListItem>
          </List>
        </Drawer>
        <IconButton className={classes.drawerButtonClose} onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon style={{color: '#fff', fontSize: '40px'}}/>
        </IconButton>
      </>
     );
}
 
export default DrawerComponent;