  import React, {useEffect} from 'react';
  import ScrollToTop from './ScrollToTop';
  import {makeStyles} from '@material-ui/core'
  import {
    Grid, 
    Box, 
    Typography
  } from '@material-ui/core'

  import Navbar from './Navbar'
  import HeaderModulo from './HeaderModulo'
  import Footer from './Footer'

  const useStyles = makeStyles(theme=>({
    jumbotronText: {
      padding: '5px 3px 2px 3px',
      textAlign: 'left',
      fontSize: '16px',
      color: '#848484',
    },
    image: {
      display: 'block', 
      maxHeight: '400px',
      maxWidth: 'auto',
    },
  }))

  const TriiTeam = () => {
    document.title = 'Trii Team'

    useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    const classes = useStyles()
    return (  
      <>
        <ScrollToTop/>
        <Navbar inModule={true} />
        <HeaderModulo
          titulo={'Trii Team'}
          contenido={`
            Mejora las comunicaciones internas
            de la empresa generando trabajo en 
            equipo a través de la resolución 
            conjunta de tareas por parte de 
            agentes pertenecientes a diferentes aéreas.
          `}
        />
        <Box display='flex' py={7} justifyContent='center' bgcolor='#f3f5fa'>
          <Box maxWidth={{xs:'90%', sm:'80%', md:'70%'}}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Box height='100%' width='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                  <img className={classes.image} src={process.env.PUBLIC_URL + './img/TriiTeam.svg'} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box display='flex' flexDirection='column'  justifyContent='center' height='100%'>
                  <Box p={2} bgcolor='#fff'>
                    <Typography className={classes.jumbotronText}>
                      Nuestro sistema cuenta con un chat interno para cada usuario, 
                      con el cual podrán establecerse conversaciones privadas 
                      entre los agentes, con la posibilidad de escribir o adjuntar archivos. 
                      Además tenemos la posibilidad de generar conversaciones entre varios 
                      agentes sobre alguna temática en particular (como un ticket). 
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box display='flex' flexDirection='column'  justifyContent='center' height='100%'>
                  <Box p={2} mt='1rem' bgcolor='#fff'>
                    <Typography className={classes.jumbotronText}>
                    Los agentes pueden trabajar en equipo sobre las 
                    discusiones dentro de los tickets mismos, y 
                    pueden tener acceso a la vista de los mismos  
                    incluso si otro equipo está trabajando en ellos de momento. 
                    </Typography>
                  </Box>
                  <Box mt='1rem' mb='1rem' p={2} bgcolor='#fff'>
                    <Typography className={classes.jumbotronText}>
                      El acceso conjunto del ticket, el contacto
                      y la conversación permite obtener mas información 
                      contextualizada ayudando a una mayor comprensión 
                      del problema para  resolver situaciones en forma 
                      rápida mediante comunicaciones más detalladas.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} align='center'>
                <Box height='100%' width='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                  <img className={classes.image} src={process.env.PUBLIC_URL + './img/triiTeam.webp'} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer/>
      </>
    );
  }
  
  export default TriiTeam;