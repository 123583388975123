import React, {useEffect} from 'react';
import Service from './Service'
import { makeStyles } from '@material-ui/core/styles'
import { 
  CssBaseline, 
  Typography,
  Grid,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
  root: {
    background: `linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url(${process.env.PUBLIC_URL + '/img/homeBackground.png'}) fixed center center`,
    backgroundSize: 'cover',
  },
  image: {
    display: 'block',
    width: 'auto',
    height: '150px'
  },
  title: {
    color: '#000',
    fontWeight: 'bold',
  },
  grid:{
    padding: '2rem 1rem 1rem 1rem'
  },
  jumbotronTitle: {
    paddingTop: '2rem',
    fontSize: '34px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '10px',
    color: '#37517e',
  },
  divider: {
    width: '5rem',
    height: '2px',
    backgroundColor: '#47b2e4',
    margin: 'auto',
    marginBottom: '2rem',
  },
}))

const Services = () => {
  const classes = useStyles();
  return (  
    <>
    <div id='services'>
    <Typography variant="h2" color="initial" align='center' className={classes.jumbotronTitle}>Funciones</Typography>
    <Grid item className={classes.divider}></Grid>
    <Box display='flex' justifyContent='center'>
    <Typography variant="h1" color="initial"></Typography>
    <Box width='70%' p={0}>
      <Grid 
        container
        direction='row'  
        justify='center'
      >
        <CssBaseline/>
        <Grid item xs={12} sm={6} md={4}>
          <Service
            image={'/img/multicanal.webp'}
            caption={'Multi-canal'}
            content={
              `Unificamos todos 
              los canales de mensajería 
              disponibles en tu empresa. 
              Telefonía, SMS, Chat Web, 
              Email, Facebook, 
              WhatsApp e Instagram.`
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Service
            image={'/img/bots.webp'}
            caption={'Bots'}
            content={
            `Nuestro Chatbot 
            permite automatizar 
            desde simples 
            mensajes de bienvenida
            hasta complejos arboles 
            de opciones. Aplica a 
            los canales WhatsApp, 
            SMS, Chat Web, E-mail 
            y Facebook.`
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>  
          <Service
            image={'/img/api.webp'}
            caption={'API'}
            content={
              `Permitimos a 
              los clientes a 
              través de sus 
              sistemas de gestión 
              enviar avisos 
              automáticos por 
              email, SMS, WhatsApp
              o llamada telefónica.`
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>  
          <Service
            image={'/img/dashboard.webp'}
            caption={'Dashboard'}
            content={`
              Visualice la información 
              importante en tiempo real, 
              llevando el control de su 
              empresa y anticipando 
              posibles acciones para mejorar 
              la productividad.
            `}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>  
          <Service
            image={'/img/reports.webp'}
            caption={'Reportes'}
            content={`
              Conoce de forma detallada
              el desarrollo de  comunicaciones
              de tu empresa.
            `}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>  
          <Service
            image={'/img/ivr.webp'}
            caption={'IVR'}
            content={`
              Automatizamos las 
              llamadas  telefónicas 
              de los clientes combinando 
              la base de datos, 
              PBX y el modulo de Trii. 
            `}
          />
        </Grid>
      </Grid>
    </Box>
    </Box>
    </div>
    </>
  );
}
 
export default Services;