import React from 'react';
import Navbar from './Navbar'
import Footer from './Footer'
import {Box, Typography} from '@material-ui/core'

const privacity = () => {
  return ( 
    <>
      <Navbar inPrivacity={true}/>
      <Box mt={12} px={4}>
        <Typography variant="h3">Política de Privacidad</Typography>
        <Typography variant="caption">08/Octubre/2019</Typography>
        <Typography>
          La presente Política de Privacidad establece los términos en que Trii usa y 
          protege la información que es proporcionada por sus usuarios al momento de utilizar la aplicación alguna de sus aplicaciones. Trii está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o 
          ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.
          <br/><br/><Typography variant="h4">¿Quiénes somos?</Typography>
          Trii es la división software de Telefonía Francia S.A, empresa de servicios que lleva 25 Años de experiencia en el medio, dedicada a la comercialización, instalación, mantenimiento y reparación de sistemas de telecomunicaciones. Desde el año 2010 empezamos a desarrollar diversas aplicaciones de software enfocadas a mejorar el manejo de los diversos canales de comunicaciones de las empresas.
          <br/>
          <br/>
          <Typography variant="h4">¿Qué tipo de datos personales recabamos?</Typography>
          Solemos recabar datos de carácter personal a través de distintos canales: nuestra web, correos electrónicos, contacto telefónico, canales de mensajería o de forma personal.
          Dependiendo del canal, recabamos tu nombre, apellido, número de teléfono, correo electrónico, nombre de tu empresa, puesto y otros datos de contacto. Si eres tú quien se pone en contacto con nosotros, también recabamos la información que nos facilites.
          Nuestro servidor almacena automáticamente un informe con datos de navegación, como tu dirección IP, la URL. También recabamos datos estadísticos sobre el sitio web, como las páginas más visitadas y la frecuencia de las visitas. No es posible identificar a nadie a partir de sus datos de navegación o de las estadísticas de la web a menos que inicie sesión en su cuenta personal en esa web.
          <br/>
          <br/>
          <Typography variant="h4">Seguridad</Typography> 
          Nos comprometemos a garantizarte que tus datos están protegidos con los máximos niveles de seguridad. Salvaguardamos tu información con medidas técnicas y organizativas para prevenir el tratamiento ilícito o no autorizado, así como pérdidas accidentales, daños o destrucción.
          Trii controla sus sistemas informáticos y de comunicaciones mediante distintas herramientas automatizadas. Esta supervisión está destinada principalmente a proteger a Trii, sus empleados, clientes y socios.
          <br/>
          <br/>
          <Typography variant="h4">Marketing</Typography>
          Es posible que tus datos personales se usen con fines publicitarios, lo que incluiría comunicaciones comerciales acerca de los productos y servicios de Trii. En caso de que la ley así lo exija, solo te enviaremos comunicaciones comerciales si previamente nos has prestado tu consentimiento explícito.
          Conservamos datos de contacto en nuestras listas de marketing hasta que el interesado solicita abandonarlas. Si así lo decides, es posible que sigamos conservando algunos datos con el fin de poder identificarte y cumplir tu solicitud de abandonar dichas listas.
          Puedes ponerte en contacto con nosotros en cualquier momento para pedirnos que dejemos de enviarte comunicaciones comerciales. Si quieres darte de baja de cualquier lista de distribución o suscripción, dispones también de la opción de «Darse de Baja» en los mensajes de correo que te enviamos. Podrás elegir entre dejar de recibir todo tipo de notificaciones o señalar las comunicaciones que no te interesan.
          <br/>
          <br/>
          <Typography variant="h4">Otros sitios web</Typography>
          Al hacer clic en un enlace de este sitio web que te redirija a otro, 
          dejará de aplicarse esta política. No nos responsabilizamos de las 
          prácticas de otros sitios con respecto al tratamiento de tus datos. 
          El uso de tus datos por parte del propietario de otra web debe enmarcarse 
          en la política de privacidad de dicha web, que te animamos a consultar.
          Cambios en esta política de privacidad Revisamos continuamente nuestra política de privacidad. Si realizamos cambios, ajustamos la fecha de «última actualización». Te animamos a consultar esta política periódicamente para estar al corriente de cómo trata Trii tus datos personales.
          
          <br/>
          <br/>
          <Typography variant="h4">Contacto</Typography>
          Si tienes alguna duda sobre esta política de privacidad o sobre nuestra 
          forma de tratar tu información, ponte en contacto con el 
          Delegado de Privacidad mediante la dirección info@trii.com.ar 
          para ser atendido en tu idioma.

          <br/>
          <br/>
          <Typography variant="h4">Domicilio:</Typography>
          San Lorenzo 1235
          Río Cuarto, Córdoba, Argentina
        </Typography>
      </Box>
      <Footer/>
    </>
  );
}
 
export default privacity;