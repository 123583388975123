import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import {
  CssBaseline,
  Typography,
  Collapse,
  Grid,
  Box,
} from '@material-ui/core'

const useStyles = makeStyles((theme)=>({
  root: {
    background: `linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url(${process.env.PUBLIC_URL + '/img/homeBackground.png'}) fixed center center`,
    backgroundSize: 'cover',
  },
  image: {
    display: 'block',
    width: 'auto',
    height: '150px'
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
  },
  grid:{
    padding: '2rem 1rem 1rem 1rem'
  },
  jumbotronTitle: {
    fontSize: '34px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '10px',
    color: '#37517e',
  },
  jumbotronText: {
    fontSize: '20px',
    color: '#848484',
  },
  divider: {
    width: '5rem',
    height: '2px',
    backgroundColor: '#47b2e4',
    margin: 'auto',
    marginBottom: '2rem',
  },
}))

const About = () => {

  const classes = useStyles()
  return (  
    <>
      <Box display='flex' justifyContent='center' id='about'>
        <Box width='70%' my={4}>
          <Typography variant="h2" color="initial" align='center' className={classes.jumbotronTitle}>Nosotros</Typography>
          <Grid item className={classes.divider}></Grid>
          <Typography variant="h5" className={classes.jumbotronText} align='center'>
            <strong>Trii</strong> es una <strong>plataforma omnicanal</strong> que te permitirá centralizar los distintos
            canales de comunicación, afianzar la relación con 
            tus clientes y mejorar el rendimiento de tu empresa 
            a través de sus distintos módulos.
          </Typography>
        </Box>
      </Box>
      <div className={classes.root}>
        <CssBaseline></CssBaseline>
        <Grid container>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="start"
            alignContent="center"
            className={classes.grid}
          >
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Box display='flex' flexDirection='column'>
                  <Box display='flex' justifyContent='center'>
                    <img className={classes.image} src={process.env.PUBLIC_URL + '/img/ticket.png'}/>
                  </Box>
                  <Box 
                    display='flex' 
                    justifyContent='center' 
                    m={2} 
                    flexDirection='column' 
                    textAlign='center'
                  >
                    <Typography className={classes.title} variant="h5" component="h2" >
                      TICKETS
                    </Typography>
                    <Typography style={{color: '#fff'}} variant="h4">
                      +30.000
                    </Typography>
                    <Typography style={{color: '#fff', fontSize: '20px'}}>
                    Tickets generados y gestionados por mes.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
              <Box display='flex' flexDirection='column'>
                  <Box display='flex' justifyContent='center'>
                    <img className={classes.image} src={process.env.PUBLIC_URL + '/img/megafono.png'}/>
                  </Box>
                  <Box 
                    display='flex' 
                    justifyContent='center' 
                    flexDirection='column' 
                    m={2} 
                    textAlign='center'
                  >
                    <Typography className={classes.title} variant="h5" component="h2">
                      LISTAS DIFUSIÓN
                    </Typography>
                    <Typography style={{color: '#fff'}} variant="h4">
                      +1.000
                    </Typography>
                    <Typography style={{color: '#fff', fontSize: '20px'}}>
                    campañas generadas por mes.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Box display='flex' flexDirection='column'>
                  <Box display='flex' justifyContent='center'>
                    <img className={classes.image} src={process.env.PUBLIC_URL + '/img/phone.png'}/>
                  </Box>
                  <Box 
                    display='flex' 
                    justifyContent='center' 
                    m={2} 
                    flexDirection='column' 
                    textAlign='center'
                  >
                    <Typography className={classes.title} variant="h5" component="h2">
                      LLAMADAS
                    </Typography>
                    <Typography style={{color: '#fff'}} variant="h4">
                      +250.000
                    </Typography>
                    <Typography style={{color: '#fff', fontSize: '20px'}}>
                    llamadas gestionadas por mes.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
              <Box 
                display='flex' 
                flexDirection='column'
                textAlign='center'
              >
                  <Box 
                    display='flex' 
                    justifyContent='center'
                  >
                    <img className={classes.image} src={process.env.PUBLIC_URL + '/img/chat.png'}/>
                  </Box>
                  <Box 
                    display='flex' 
                    justifyContent='center' 
                    m={2} 
                    flexDirection='column'
                  > 
                    <Typography className={classes.title} variant="h5" component="h2">
                      COMUNICACIONES
                    </Typography>
                    <Typography style={{color: '#fff'}} variant="h4">
                      +900.000
                    </Typography>
                    <Typography style={{color: '#fff', fontSize: '20px'}}>
                    comunicaciones generadas por mes.
                      <Typography style={{color: '#fff', fontSize: '20px'}}>
                        (WhatsApp, facebook, sms)
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
 
export default About;