import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {makeStyles} from '@material-ui/core'
import{
  Box, 
  Typography, 
  Grid,
  CssBaseline,
} from '@material-ui/core'

const useStyles = makeStyles(theme=>({
  image: {
    display: 'block',
    maxWidth: '240px',

  },
  title: {
    fontSize: '28px',
    lineHeight: '1',
    textTransform: 'uppercase',
    fontWeight: '600',
    color: '#37517e',
  },
  parrafo: {
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '0',
    fontFamily: '"Jost", sans-serif',
    color: '#5e5e5e',
  },
  h4Footer: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#37517e',
    position: 'relative',
  },
  linkUl:{
    listStyle: 'none',
    padding: '0',
    margin: '0',
  },
  iconLink:{
    color: '#47b2e4',
    fontSize: '22px',
    lineHeight: '1',
    fontWeight: 'bold',
  },
  footerLink: {
    textDecoration: 'none',
    color: '#777777',
    transition: '0.3s',
    display: 'inline-block',
    lineHeight: '1',
    '&:hover':{
      color: '#47b2e4',
    }
  },
  listLink:{
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (  
    <>
      <CssBaseline/>
      <Box 
        display='flex' 
        justifyContent='center' 
        width='100%'
        bgcolor='#f3f5fa'
        py={3}
        borderTop= '1px solid #47b2e4'
      >
        <Box minWidth='70%' justifyContent='center'>
          <Grid container justify='center' align='center'>
            <Grid item xs={12} sm={12} md={4}>
              <Box display='flex' justifyContent={{sm:'center', xs:'center', md:'center'}}>
                <img className={classes.image} src={process.env.PUBLIC_URL + '/img/Trii.svg'} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box display='flex' flexDirection='column' justifyContent='center' pt={2}>
              
                <p className={classes.parrafo}>
                  San Lorenzo 1235<br/>
                  Río Cuarto, Córdoba<br/>
                  Argentina<br/><br/>
                  <strong>Teléfono:</strong><br/>
                  +549 358 518-3339<br/>
                  +549 358 430-9003<br/><br/>
                  <strong>Email:</strong><br/>
                  info@trii.com.ar
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box display='flex' flexDirection='column' justifyContent='center' pt={2}>
                <Typography className={classes.h4Footer}>Navegación</Typography>
                <ul className={classes.linkUl}>
                  
                  <li className={classes.listLink}><NavigateNextIcon className={classes.iconLink}/><a className={classes.footerLink} href="#inicio">Inicio</a></li>
                  <li className={classes.listLink}><NavigateNextIcon className={classes.iconLink}/><a className={classes.footerLink} href="#nosotros">Sobre nosotros</a></li>
                  <li className={classes.listLink}><NavigateNextIcon className={classes.iconLink}/><a className={classes.footerLink} href="#modulos">Modulos</a></li>
                  <li className={classes.listLink}><NavigateNextIcon className={classes.iconLink}/><a className={classes.footerLink} href="#funciones">Funciones</a></li>
                  <li className={classes.listLink}><NavigateNextIcon className={classes.iconLink}/><a className={classes.footerLink} href="#contacto">Contacto</a></li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box 
        width='100%'
        // height='4rem' 
        display='flex' 
        // justifyContent='center' 
        justifyContent='space-around'
        bgcolor='#37517e'
        color='#fff'
        alignItems='center'
        paddingTop='3px'
        // paddingBottom='3px'
      >
        <Typography>© Copyright <strong>Trii</strong>. Todos los derechos reservados</Typography>
        <a href="http://qr.afip.gob.ar/?qr=5MDIJFbMLaH1b5C1Dty2ag,," target="_F960AFIPInfo">
          <img style={{height: '64px'}} src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" />
        </a>
      </Box>
    </>
  );
}
 
export default Footer;
