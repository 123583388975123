import React, {useEffect} from 'react';
import ScrollToTop from './ScrollToTop';
import {makeStyles} from '@material-ui/core'
import {
  Grid, 
  Box, 
  Typography
} from '@material-ui/core'

import Navbar from './Navbar'
import HeaderModulo from './HeaderModulo'
import Footer from './Footer'

const useStyles = makeStyles(theme=>({
  jumbotronText: {
    padding: '5px 3px 2px 3px',
    textAlign: 'left',
    fontSize: '16px',
    color: '#848484',
  },
}))


const TriiTickets = () => {
  document.title = 'Trii Tickets'

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  const classes = useStyles();
  return (  
    <>
      <ScrollToTop/>
      <Navbar inModule={true}/>
      <HeaderModulo
        titulo='trii tickets'
        contenido={`
          El módulo permite 
          dar un seguimiento 
          adecuado a las tareas 
          particulares de tu empresa, 
          permitiendo mejorar la 
          organización y agilizar 
          las gestiones de las mismas.
        `}
      />
      <Box width='100%' display='flex' justifyContent='center' bgcolor='#f3f5fa' py={5}>
        <Box width={{xs: '100%', sm: '60%', md: '50%', lg: '50%'}} display='flex' flexDirection='column'>
          <Box 
            maxWidth={{xs:'100%', sm:'100%', md: '60', lg:'60%'}}
            pt={2} 
            pb={1}
            display='flex' 
            alignItems='center' 
            justifyContent='flex-start' 
          >
            <img src={process.env.PUBLIC_URL + '/img/clienteTicket.webp'}/>
            <Typography className={classes.jumbotronText}>
              Asigna las tareas a un usuario o grupo
              dentro de tu empresa para una resolución eficaz.
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/clockMarketing.webp'}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>
                Establece la prioridad de tus tickets 
                así como tiempos máximos de resolución 
                o fechas predeterminadas.
              </Typography>
            </Box>
          </Box>
          <Box 
            pt={2}
            pb={1} 
            maxWidth={{xs:'100%', sm:'100%', md: '60%', lg:'60%'}}
            display='flex' 
            justifyContent='flex-start' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/bellMarketing.webp'}/>
            <Typography className={classes.jumbotronText}>
              Envía notificaciones de forma inmediata 
              a través de WhatsApp a tus clientes para que 
              puedan  recibir información actualizada 
              de los cambios de estado.
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/seguimiento.webp'}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>
                Realiza el seguimiento 
                a través de estados personalizados.
              </Typography>
            </Box>
          </Box>
          <Box 
            maxWidth={{xs:'100%', sm:'100%', md: '60', lg:'60%'}}
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-start' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/calendarioMarketing.webp'}/>
            <Typography className={classes.jumbotronText}>
              Te será muy fácil mantener 
              tu agenda ordenada a través 
              de la vista calendario.
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1} 
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/ubicacion.webp'}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>
                Podrás crear, gestionar y cerrar tickets desde cualquier parte.
              </Typography>
            </Box>
          </Box>
          <Box 
            maxWidth={{xs:'100%', sm:'100%', md: '60', lg:'60%'}}
            pt={2} 
            pb={1} 
            display='flex' 
            justifyContent='flex-start  ' 
            alignItems='center'
          > 
            <img src={process.env.PUBLIC_URL + '/img/ticketMarketing.webp'}/>
            <Typography className={classes.jumbotronText}>
              Asocia los tickets a tus contactos...
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
 
export default TriiTickets;