import React from 'react';
import {makeStyles} from '@material-ui/core/styles'
import {
  Box,
  Modal,
  Button,
  Typography
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme=>({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: '10px',
    color: '#fff',
    backgroundColor: '#47b2e4',
    borderRadius: '99px',
    '&:hover':{
      backgroundColor: '#209dd8'
    }
  },
  icon: {
    color: '#fff',
    fontSize: '70px'
  },
  outline: {
    outline: 'none'
  },
  header:{
    borderTopLeftRadius: '13px',
    borderTopRightRadius: '13px'
  },
}))

const ModalForm = ({handleClose, titulo, onClose, error}) => {
  const classes = useStyles()
  return (  
    <>
    <Modal
      open={true}
      className={classes.modal}
    >
      <Box 
        className={classes.outline}
        borderRadius='13px'
        display='flex' 
        flexDirection='column' 
        justifyContent='center' 
        alignItems='center' 
        bgcolor='#fff'
        width='20rem'
      >
        <Box 
          height='40%' 
          width='100%' 
          bgcolor='#47b2e4'
          display='flex'
          justifyContent='center'
          p={2}
          className={classes.header}
        >
          {error?
          <ErrorOutlineIcon className={classes.icon}/>
          :
          <CheckCircleOutlineIcon className={classes.icon}/>
          }
        </Box>
        <Box py={2} display='flex' flexDirection='column' justifyContent='center'>
          <Typography varian='h2' style={{color: error?'red':null}}><strong>{titulo}</strong></Typography>
          <Button onClick={onClose} className={classes.button}>ACEPTAR</Button>
        </Box>
      </Box>
    </Modal>
    </>
  );
}
 
export default ModalForm;