import React, {useEffect} from 'react';
import ScrollToTop from './ScrollToTop';
import {makeStyles} from '@material-ui/core'
import {
  Grid, 
  Box, 
  Typography
} from '@material-ui/core'

import Navbar from './Navbar'
import HeaderModulo from './HeaderModulo'
import Footer from './Footer'

const useStyles = makeStyles(theme=>({
  jumbotronText: {
    padding: '5px 3px 2px 3px',
    textAlign: 'left',
    fontSize: '16px',
    color: '#848484',
  },
}))


const TriiCobranzas = () => {
  document.title = 'Trii Cobranzas'

  const classes = useStyles();
  return (  
    <>
      <ScrollToTop/>
      <Navbar inModule={true}/>
      <HeaderModulo
        titulo='trii cobranzas'
        contenido={`
          Realiza una cobranza 
          eficiente afianzando 
          la relación con tus 
          deudores y agiliza 
          las gestiones de cobro 
          de manera más simple.
        `}
      />
      <Box width='100%' display='flex' justifyContent='center' bgcolor='#f3f5fa' py={5}>
        <Box width={{xs: '100%', sm: '60%', md: '50%', lg: '50%'}} display='flex' flexDirection='column'>
          <Box 
            maxWidth={{xs:'100%', sm:'100%', md: '60', lg:'60%'}}
            pt={2} 
            pb={1}
            display='flex' 
            alignItems='center' 
            justifyContent='flex-start' 
          >
            <img src={process.env.PUBLIC_URL + '/img/money.png'} style={{width: '150px'}}/>
            <Typography className={classes.jumbotronText}>
              Realiza una cobranza fácil y ordenada
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/listaCobranza.webp'}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>
                Mantiene la contabilidad de tu empresa 
                con la posibilidad de administrar la 
                lista de tus deudores y organizar la 
                cobranza
              </Typography>
            </Box>
          </Box>
          <Box 
            pt={2}
            pb={1} 
            maxWidth={{xs:'100%', sm:'100%', md: '60%', lg:'60%'}}
            display='flex' 
            justifyContent='flex-start' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/notificacionCobranza.webp'}/>
            <Typography className={classes.jumbotronText}>
              Envío de notificaciones programables 
              para informar sobre vencimientos y 
              recordatorios, a través de WhatsApp, 
              Correo electrónico o mensajes de voz.
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/notificacionesPay.webp'}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>
                Gestiona planes de pago a 
                tus clientes y mantenlos 
                informados sobre sus estados contables
              </Typography>
            </Box>
          </Box>
          <Box 
            maxWidth={{xs:'100%', sm:'100%', md: '60', lg:'60%'}}
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-start' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/payday.webp'}/>
            <Typography className={classes.jumbotronText}>        
              Mejora las relaciones de la empresa 
              con tus clientes habituales y potenciales.
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1} 
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/teamCobranza.webp'}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>          
                A través de filtros y estados personalizados 
                podrás actualizar tus listas de cobranza 
                de manera muy práctica
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
 
export default TriiCobranzas;