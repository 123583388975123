import React from 'react';
import Card from './Card'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box } from '@material-ui/core';

const useStyles=makeStyles(theme=>({
  title:{
    textAlign: 'center',
    paddingTop: '2rem',
    marginBottom: '10px',
    fontSize: '34px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#37517e',
  },  
  root:{
    backgroundColor: '#f3f5fa',
    paddingBottom: '2rem',
  },
  divider: {
    width: '5rem',
    height: '2px',
    backgroundColor: '#47b2e4',
    margin: 'auto',
    marginBottom: '1rem',
  },
}))

const Modulos = () => {
  const images={
    triiCall: "'/img/TriiCall.svg'",
    triiChat: '/img/triiChat.svg',
    triiCobranzas: "'/img/triiCobranzas.svg'",
    triiMarketing: "'/img/triiMarketing.svg'",
    triiService: "'/img/triiService.svg'",
    triiTeam: "'/img/triiTeam.svg'",
    triiTickets: "'/img/triiTickets.svg'",
  }

  const classes = useStyles();
  return ( 
    <>
      <div className={classes.root} id='modules'>
        <Typography 
          className={classes.title} 
          variant="h3" 
          color="initial"
        >
          Modulos
        </Typography>
        <div className={classes.divider}></div>
        <Box display='flex' justifyContent='center'>
          <Box maxWidth='80%'> 
            <Grid 
              container
              zeroMinWidth
            >
              <Grid spacing item xs={12} sm={6} md={4} align="center">
                <Card
                  image='img/TriiTeam.svg'
                  title='Trii Team'
                  subtitle='Fomenta el trabajo en equipo.'
                  caption={`
                    Crea salas de discusión 
                    para ciertas temáticas. 
                    Mantiene los sectores 
                    comunicados.
                  `}
                  path={'/triiTeam'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} align="center">
                <Card
                  image='img/TriiChat.svg'
                  title='Trii Chat'
                  subtitle='Unifica los canales de comunicación.'
                  caption={`​
                    Asigna las conversaciones 
                    a distintos usuarios.
                    El historial y los datos 
                    de contacto en un solo lugar.
                  `}
                  path={'/triiChat'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} align="center">
                <Card
                  image='img/TriiMarketing.svg'
                  title='Trii Marketing'
                  subtitle='Envíos masivos de mensajería.'
                  caption={`
                    Comunica novedades, 
                    promociones y notificaciones.
                    Genera nuevas ventas.
                  `}
                  path={'/triiMarketing'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} align="center">
                <Card
                  image='img/TriiTickets.svg'
                  title='Trii Ticket'
                  subtitle='Ordena las tareas.'
                  caption={`
                    Dale seguimiento a pedidos, 
                    reclamos, consultas y entre otros.
                    Notificaciones con WhatsApp.
                  `}
                  path={'/triiTickets'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} align="center">
                <Card
                  image='img/TriiService.svg'
                  title='Trii Service'
                  subtitle='Simplifica las tareas de reparación.'
                  caption={`
                    Coordina los grupos 
                    de servicio.
                    Envía notificaciones 
                    de cambio de estado.
                  `}
                  path={'/triiService'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} align="center">
                <Card
                    image='img/TriiCobranzas.svg'
                    title='Trii Cobranzas'
                    subtitle='Genera registros contables actualizados.'
                    caption={`
                      Afianza la relación 
                      con tus clientes.
                      Facilita la cobranza.
                    `}
                    path={'/triiCobranzas'}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </>
  );
}
 
export default Modulos;