import React, {useEffect} from 'react';
import ScrollToTop from './ScrollToTop';
import {makeStyles} from '@material-ui/core'
import {
  Grid, 
  Box, 
  Typography
} from '@material-ui/core'

import Navbar from './Navbar'
import HeaderModulo from './HeaderModulo'
import Footer from './Footer'

const useStyles = makeStyles(theme=>({
  jumbotronText: {
    padding: '5px 3px 2px 3px',
    textAlign: 'left',
    fontSize: '16px',
    color: '#848484',
  },
}))

const TriiService = () => {
  document.title = 'Trii Service'
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  const classes=useStyles()
  return (  
    <>
      <ScrollToTop/>
      <Navbar inModule={true}/>
      <HeaderModulo
        titulo='trii service'
        contenido={`
          Organiza de manera 
          practica las reparaciones 
          de tu empresa, gestiona 
          los pedidos de servicio 
          técnico y coordina 
          los grupos de servicio.
        `}
      />
      <Box width='100%' display='flex' justifyContent='center' bgcolor='#f3f5fa' py={5}>
        <Box width={{xs: '100%', sm: '60%', md: '50%', lg: '50%'}} display='flex' flexDirection='column'>
          <Box 
            maxWidth={{xs:'100%', sm:'100%', md: '60', lg:'60%'}}
            pt={2} 
            pb={1}
            display='flex' 
            alignItems='center' 
            justifyContent='flex-start' 
          >
            <img src={process.env.PUBLIC_URL + '/img/technical.webp'} style={{width: '150px'}}/>
            <Typography className={classes.jumbotronText}>          
              Gestiona las reparaciones o servicio 
              técnico en general mediante los 
              tickets de soporte asignando 
              estados personalizados.
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/wspNoti.png'} style={{width: '150px'}}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>
                Envía notificaciones 
                de WhatsApp a tus clientes 
                para que conozcan el 
                estado de las reparaciones
              </Typography>
            </Box>
          </Box>
          <Box 
            pt={2}
            pb={1} 
            maxWidth={{xs:'100%', sm:'100%', md: '60%', lg:'60%'}}
            display='flex' 
            justifyContent='flex-start' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/users.webp'}/>
            <Typography className={classes.jumbotronText}>
              Asigna tus reparaciones 
              a un usuario o a un grupo 
              para su posterior resolución
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/calender.webp'}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>
                Organiza tu agenda de 
                trabajo de manera sencilla 
                a partir de la vista calendario
              </Typography>
            </Box>
          </Box>
          <Box 
            maxWidth={{xs:'100%', sm:'100%', md: '60', lg:'60%'}}
            pt={2} 
            pb={1}
            display='flex' 
            justifyContent='flex-start' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/clock.webp'}/>
            <Typography className={classes.jumbotronText}>        
              Programa tiempos máximos para tus reparaciones.
            </Typography>
          </Box>
          <Box 
            pt={2} 
            pb={1} 
            display='flex' 
            justifyContent='flex-end' 
            alignItems='center'
          >
            <img src={process.env.PUBLIC_URL + '/img/editUser.webp'}/>
            <Box maxWidth={{xs:'100%', sm:'100%',md:'40%'}}>
              <Typography className={classes.jumbotronText}>           
                Añadí notas, adjunta fotos 
                desde una ubicación y actualiza 
                las tareas de servicio y 
                manteniendo al día a los agentes 
                del sector correspondiente.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
 
export default TriiService;