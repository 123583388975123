import React, {useEffect} from 'react';
import ScrollToTop from './ScrollToTop';
import {makeStyles} from '@material-ui/core'
import {
  Grid, 
  Box, 
  Typography
} from '@material-ui/core'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Navbar from './Navbar'
import HeaderModulo from './HeaderModulo'
import Footer from './Footer'

const useStyles = makeStyles(theme=>({
  jumbotronText: {
    padding: '5px 3px 2px 3px',
    textAlign: 'left',
    fontSize: '16px',
    color: '#848484',
  },
  image: {
    display: 'block', 
    maxHeight: '20rem',
    maxWidth: '100%',
  },
  jumbotronTitle: {
    fontSize: '34px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '10px',
    color: '#37517e',
  },
  divider: {
    width: '5rem',
    height: '2px',
    backgroundColor: '#47b2e4',
    margin: 'auto',
    marginBottom: '2rem',
  },
}))


const TriiChat = () => {
  document.title = 'Trii Chat'

  console.log(window.location)
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  const classes = useStyles()
  return (  
    <>
      <ScrollToTop/>
      <Navbar inModule={true}/>
      <HeaderModulo
        titulo={'Trii Chat'}
        contenido={
          `Mejora la atención al 
          cliente agilizando la 
          respuesta de tus conversaciones 
          a través de canales  unificados, 
          brindando una atención mas  
          personalizada`
        }
      />
      <Box display='flex' justifyContent='center' pt={7} width='100%' bgcolor='#f3f5fa'>
        <Box maxWidth={{xs:'100%', sm:'90%', md:'90%'}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <Box height='auto' display='flex' justifyContent='center'>
                <img className={classes.image} src={process.env.PUBLIC_URL + './img/triichat1.webp'} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box display='flex' flexDirection='column' justifyContent='center' height='100%'>
                <Box pt={1} display='flex'>
                  <ArrowRightIcon style={{color: '#47b2e4', paddingTop: '2px', fontSize: '30px'}}/>
                  <Typography className={classes.jumbotronText}>
                    <strong style={{color: '#47b2e4'}}>1)</strong> Bandeja unificada
                  </Typography>
                </Box>
                <Box pt={1} display='flex'>
                  <ArrowRightIcon style={{color: '#47b2e4', paddingTop: '2px', fontSize: '30px'}}/>
                  <Typography className={classes.jumbotronText}>
                  <strong style={{color: '#47b2e4'}}>2)</strong> Brinda una atención personalizada 
                    a partir de distintos agentes
                  </Typography>
                </Box>
                <Box pt={1} display='flex'>
                  <ArrowRightIcon style={{color: '#47b2e4', paddingTop: '2px', fontSize: '30px'}}/>
                  <Typography className={classes.jumbotronText}>
                  <strong style={{color: '#47b2e4'}}>3)</strong> Podrás asignar las conversaciones 
                    a usuarios o grupos internos
                  </Typography>
                </Box>
                <Box pt={1} display='flex'>
                  <ArrowRightIcon style={{color: '#47b2e4', paddingTop: '2px', fontSize: '30px'}}/>
                  <Typography className={classes.jumbotronText}>
                    <strong style={{color: '#47b2e4'}}>4)</strong> Todo el historial de 
                    tus conversaciones
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>  
      <Box display='flex' justifyContent='center' width='100%' bgcolor='#f3f5fa' pt={7} pb={7}>
        <Box maxWidth={{xs:'100%', sm:'90%', md:'90%'}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <Box py={2} display='flex' flexDirection='column' justifyContent='center' height='100%'>
              <Typography variant="h2" color="initial" align='center' className={classes.jumbotronTitle}>Otras funciones</Typography>
              <Grid item className={classes.divider}></Grid>
                <Box pt={1} display='flex'>
                  <ArrowRightIcon style={{color: '#47b2e4', paddingTop: '2px', fontSize: '30px'}}/>
                  <Typography className={classes.jumbotronText}>
                    <strong style={{color: '#47b2e4'}}>1)</strong> Con la posibilidad de transferir 
                    las comunicaciones a usuarios o grupos, 
                    para que la continúe otro agente
                  </Typography>
                </Box>
                <Box pt={1} display='flex'>
                  <ArrowRightIcon style={{color: '#47b2e4', paddingTop: '2px', fontSize: '30px'}}/>
                  <Typography className={classes.jumbotronText}>
                  <strong style={{color: '#47b2e4'}}>2)</strong> Gestiona los datos 
                  de contacto de tus clientes
                  </Typography>
                </Box>
                <Box pt={1} display='flex'>
                  <ArrowRightIcon style={{color: '#47b2e4', paddingTop: '2px', fontSize: '30px'}}/>
                  <Typography className={classes.jumbotronText}>
                  <strong style={{color: '#47b2e4'}}>3)</strong> Según el canal de comunicación 
                  podrás desde enviar textos, audios y adjuntar archivos 
                  </Typography>
                </Box>
                <Box pt={1} display='flex'>
                  <ArrowRightIcon style={{color: '#47b2e4', paddingTop: '2px', fontSize: '30px'}}/>
                  <Typography className={classes.jumbotronText}>
                    <strong style={{color: '#47b2e4'}}>4)</strong> Crea ticket a partir de las mismas conversaciones
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Box height='auto' display='flex' justifyContent='center'>
                <img className={classes.image} src={process.env.PUBLIC_URL + './img/triichat1.webp'} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>  
      <Footer/>
    </>
  );
}
 
export default TriiChat;