import React, {Fragment} from 'react';
import {ThemeProvider} from '@material-ui/core/styles'
import {BrowserRouter as Router, Route, Link} from 'react-router-dom'

import theme from './style/theme'

import Dashboard from './components/Dashboard'
import TriiTeam from './components/TriiTeam'
import Page from './components/Page'
import TriiChat from './components/TriiChat';
import TriiMarketing from './components/TriiMarketing';
import TriiTickets from './components/TriiTickets';
import TriiService from './components/TriiService';
import TriiCobranzas from './components/TriiCobranzas'
import privacity from './components/privacity';

function App() {

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Router>
          <Route path='/' exact component={Page}/>
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/triiTeam" component={TriiTeam}/>
          <Route path="/triiChat" component={TriiChat}/>
          <Route path="/triiMarketing" component={TriiMarketing}/>
          <Route path="/triiTickets" component={TriiTickets}/>
          <Route path="/triiService" component={TriiService}/>
          <Route path="/triiCobranzas" component={TriiCobranzas}/>
          <Route path="/privacidad" component={privacity}/>
        </Router>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
