import React, {useState, useEffect} from 'react';
import Modal from './ModalForm'
import Spinner from './Spinner'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { 
  CssBaseline, 
  Typography,
  Collapse,
  Grid,
  Box,
  TextField,
  Button,
} from '@material-ui/core';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ModalForm from './ModalForm';


const useStyles = makeStyles((theme)=>({
  jumbotronTitle: {
    paddingTop: '2rem',
    fontSize: '34px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '10px',
    color: '#37517e',
  },
  divider: {
    width: '5rem',
    height: '2px',
    backgroundColor: '#47b2e4',
    margin: 'auto',
    marginBottom: '2rem',
  },
  input: {
    paddingBottom: '1rem',
  },
  button:{
      borderRadius: '999px',
      backgroundColor: '#47b2e4',
      color: '#fff',
      padding: '10px 14px 10px 14px',
      margin: '0 auto',
      transition: '.3s',
      '&:hover':{
        backgroundColor: '#209dd8',
      },
  },
  contactInfo: {
    marginLeft: '10px',
    fontSize: '17px',
    color: '#6182ba',
  },
  icon: {
    backgroundColor: '#f3f5fa',
    border: '1px solid #37517e',
    borderRadius: '100%',
    padding: '4px 5px 0px 5px',
    margin: '0 2px 0 2px',
    color: '#37517e',
    transition: '0.3s',
    '&:hover':{
      color: '#fff',
      backgroundColor: '#47b2e4',
      border: '1px solid #47b2e4',
    },
  },
  infoTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#37517e'
  },
}))

const Contact = () => {

  const classes = useStyles();

  const {handleSubmit, register, errors} = useForm();  

  const [modalEnviado, setModalEnviado] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [spinner, setSpinner] = useState(false)

  const onSubmit=async (data, e)=>{
    e.preventDefault()
    setSpinner(true)
    fetch('https://telefoniafrancia.trii.com.ar/api/v1/canales/forms/new', {
      method: 'post',
      body : JSON.stringify(data),
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(async (response) => {
      console.log(response)
      if(response.status == 200){ 
        let rta = await response.json();
        console.log(rta)
        setSpinner(false)
        setModalEnviado(true)
        e.target.reset()
      }
      else{
        setSpinner(false)
        console.log("error API")
        setModalError(true)
      }
    })
    .catch(function(error) {
      setSpinner(false)
      console.log("errorCatch", error)
      setModalError(true)
    });
  }
  return (  
    <>
    <div id='contact'>
      <CssBaseline/>
      <Typography variant="h2" color="initial" align='center' className={classes.jumbotronTitle}>Contacto</Typography>
      <Grid item className={classes.divider}></Grid>
      <Box width='100%' display='flex' justifyContent='center' pb='2rem'>
        <Box width={{xs: '80%', sm:'80%', md:'70%'}}>
          <Grid container justify='center'>
            <Grid item xs={12} sm={12} md={5}>
            <Box 
                display='flex' 
                flexDirection='column'
                p={2} 
                m={2}
                minHeight={{xs:'auto', sm:'auto', md: '439px'}}
                boxShadow='0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
                borderTop='3px solid #47b2e4'
              >
                <Box py={2}>
                  <Box display='flex' px={3} py={1}>
                    <DoneAllOutlinedIcon style={{color: '#47b2e4'}}/><Typography className={classes.contactInfo}>Conoce todos los beneficios que tiene <stong>Trii</stong> para tu empresa.</Typography>
                  </Box>
                  <Box display='flex' px={3} py={1}>
                    <DoneAllOutlinedIcon style={{color: '#47b2e4'}}/><Typography className={classes.contactInfo}>Recibí toda la información que necesitas al instante.</Typography>
                  </Box>
                  <Box display='flex' px={3} py={1}>
                    <DoneAllOutlinedIcon style={{color: '#47b2e4'}}/><Typography className={classes.contactInfo}>Solicita una demostración dejando tus datos en el Fomulario.</Typography>
                  </Box>
                </Box>
                <Box display='flex' justifyContent='flex-start' ml='1rem' mb='4px'><Typography className={classes.infoTitle}>Nuestras redes sociales:</Typography></Box>
                <Box display='flex' justifyContent='flex-start' ml='1rem'>
                  <a className={classes.icon} href='https://www.facebook.com/trii.soluciones/' target='_BLANK'><FacebookIcon style={{fontSize: '20px'}}/></a>
                  <a className={classes.icon} href='https://www.instagram.com/trii.soluciones/' target='_BLANK'><InstagramIcon style={{fontSize: '20px'}}/></a>
                  <a className={classes.icon} href='https://api.whatsapp.com/send?phone=5493585183339' target='_BLANK'><WhatsAppIcon style={{fontSize: '20px'}}/></a>
                  
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <Box 
                display='flex' 
                minHeight='439px'
                maxHeight='auto'
                flexDirection='column' 
                justifyContent='center' 
                p={2} 
                m={2}
                boxShadow='0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
                borderTop='3px solid #47b2e4'
              >
                <form onSubmit={handleSubmit(onSubmit)} action='/form' method='POST'>
                  <Box display='flex' flexDirection='column' py={1}>
                    <TextField 
                      variant='outlined' 
                      className={classes.input} 
                      name='nombre' 
                      label='Nombre'
                      id='nombre' 
                      inputRef={register({
                        required: 'Nombre es requerido',
                        minLength: 3,
                        maxLength: 23,
                      })}
                      error={Boolean(errors.nombre)}
                      helperText={errors.nombre?.message}
                    />
                    <TextField 
                      variant='outlined' 
                      className={classes.input} 
                      name='telefono' 
                      label='Teléfono' 
                      id='telefono'
                      inputRef={
                        register({
                          required: false
                        })
                      }
                    />
                    <TextField
                      variant='outlined' 
                      className={classes.input} 
                      name='email' 
                      label='Email' 
                      inputRef={register({
                        required: 'Email obligatorio',
                        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                      })}
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                    />
                    <TextField 
                      variant='outlined' 
                      rowsMax={4}
                      className={classes.input} 
                      name='mensaje' 
                      id='mensaje'
                      label='Escribe tu mensaje aqui...'
                      inputRef={
                        register({
                          required: false
                        })
                      }
                    />
                    <Button type='submit' className={classes.button} size="medium" disabled={spinner}>
                      Enviar mensaje
                    </Button>
                    {spinner?
                    <Spinner/>
                    :null}
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      </div>
      {modalEnviado?
        <ModalForm
          onClose={()=>setModalEnviado(false)}
          titulo={'Enviado correctamente!'}
          error={false}
        />
      :null}
      {modalError?
        <ModalForm
          onClose={()=>setModalError(false)}
          titulo={'Error al enviar el formulario!'}
          error={true}
        />
      :null}
    </>
  );
}
 
export default Contact;