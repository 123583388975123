import {createMuiTheme} from '@material-ui/core/';


const theme = createMuiTheme({
    palette: {
        primary:{
            main: '#47b2e4'
        },
        secondary:{
            main: '#ffffff'
        },
    },  
    typography: {
      h1: {
        fontFamily: '"Jost", sans-serif;'
      },
      h2: {
        fontFamily: '"Jost", sans-serif;'
      },
      h3: {
        fontFamily: '"Jost", sans-serif;'
      },
      h4: {
        fontFamily: '"Jost", sans-serif;'
      },
      h5: {
        fontFamily: '"Jost", sans-serif;'
      },
      h6: {
        fontFamily: '"Jost", sans-serif;'
      },
    }
});

export default theme;