import React from 'react';
import Navbar from './Navbar'
import Footer from './Footer'
import Contact from './Contact'
import {makeStyles} from '@material-ui/core'
import {
  CssBaseline,
  Typography,
  Collapse,
  Grid,
  Box,
} from '@material-ui/core'
import HeaderModulo from './HeaderModulo';
import ScrollToTop from './ScrollToTop';

const useStyles = makeStyles((theme)=>({
  jumbotronText: {
    padding: '5px 3px 2px 3px',
    textAlign: 'left',
    fontSize: '16px',
    color: '#848484',
  },
  image: {
    maxHeight: '260px',
    minWidth: '70%',
  },
}))

const Dashboard = () => {
  const classes = useStyles();
  return (  
    <>
      <ScrollToTop/>
      <Navbar/>
      <HeaderModulo
        titulo={`Dashboard`}
        contenido={`
          Es una herramienta de gestión  monitoriza, analiza y
          muestra  de manera visual los indicadores clave de
          desempeño, métricas y datos fundamentales para hacer
          un seguimiento del estado de tu empresa.
        `}
      />
      <Box display='flex' py={7} justifyContent='center' bgcolor='#f3f5fa'>
        <Box width='80%' maxWidth='auto' py={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} justifyContent='center'>
              <Box display='flex' justifyContent='center'>
                <img className={classes.image} src={process.env.PUBLIC_URL + '/img/dashboardPage.png'}/>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} justifyContent='center'>
              <Box 
                display='flex' 
                flexDirection='column' 
                alignItems='center'
                height='100%'
              >
                <Box 
                  bgcolor='#fff' 
                  mt='1rem'
                  p={1} 
                  width='90%' 
                  minHeight='100px'
                >
                  <Typography className={classes.jumbotronText}>
                    <strong>1)</strong> En el tablero de control se tiene acceso a toda 
                    la información actualizada mediante gráficos y 
                    estadísticas para una comprensión mas rápida y 
                    sencilla del flujo de comunicaciones.
                  </Typography>
                </Box>
                <Box 
                  bgcolor='#fff' 
                  mt='1rem' 
                  p={1}
                  width='90%' 
                  minHeight='100px'
                >
                  <Typography className={classes.jumbotronText}>
                    <strong>2)</strong> Podrás obtener datos al momento y de forma precisa sobre los los distintos módulos.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={4} >
                <Box width='100%' display='flex' justifyContent='center'>
                  <Box 
                    bgcolor='#fff' 
                    mt='1rem' 
                    p={1}
                    width='90%' 
                    minHeight='100px'
                  >
                    <Typography className={classes.jumbotronText}>
                      <strong>3)</strong> Te ayudara a tomar decisiones y 
                      llevar a cabo acciones partiendo de 
                      la información recabada, con la posibilidad 
                      de personalizar los sectores de monitoreo.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box width='100%' display='flex' justifyContent='center'>
                  <Box 
                    bgcolor='#fff' 
                    mt='1rem' 
                    p={1}
                    width='90%' 
                    minHeight='100px'
                  >
                    <Typography className={classes.jumbotronText}>
                      <strong>4)</strong> Podrás establecer tiempos para realizar 
                      el monitoreo, ya sea periodos prolongados, 
                      cortos o simplemente tener acceso a 
                      la información en tiempo real.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box width='100%' display='flex' justifyContent='center'>
                  <Box 
                    bgcolor='#fff' 
                    mt='1rem' 
                    p={1}
                    width='90%' 
                    minHeight='100px'
                  >
                    <Typography className={classes.jumbotronText}>
                      <strong>5)</strong> Podrás llevar un relevamiento 
                      mas minucioso del desempeño de 
                      tus agentes. 
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
 
export default Dashboard;