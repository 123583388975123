import React from 'react';
import { makeStyles } from '@material-ui/core'
import {
  Box,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme)=>({
  jumbotronTitle: {
    fontSize: '34px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '10px',
    color: '#fff',
  },
  jumbotronText: {
    textAlign: 'left',
    fontSize: '20px',
    color: '#fff',
  },
}))

const HeaderModulo = ({titulo, contenido}) => {
  const classes = useStyles();
  return (
    <>
      <Box
        bgcolor='#37517e'
        pt='90px'
        pb='3rem'
      >
        <Box
          width='60%'
          maxWidth='80%'
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          px={{xs:'30px', md: '100px'}}
        >
          <Typography variant="h2" color="initial" align='center' className={classes.jumbotronTitle}>{titulo}</Typography>
          <Typography variant="h2" color="initial" align='center' className={classes.jumbotronText}>
            {contenido}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default HeaderModulo;