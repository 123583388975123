import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme=>({
  spinner:{
    color: '#47b2e4'
  },
}))

const Spinner = () => {
  const classes = useStyles();
  return (  
    <>
    <Box width='100%' display='flex' justifyContent='center' pt={1}>
      <CircularProgress className={classes.spinner}/>
    </Box>
    </>
  );
}
 
export default Spinner;