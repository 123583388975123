import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import {
  Card, 
  CardContent, 
  Button, 
  Typography, 
  Box, 
} from '@material-ui/core';


const useStyles = makeStyles({
  root: {
    maxWidth: 'auto',
    minWidth: '250px',
    minHeight: '400px',
    height: 'auto',
    borderRadius: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  button:{
    marginBottom: '1rem',
    borderRadius: '6px',
    border: '2px solid #47b2e4',
    color: '#47b2e4',
    width: '8rem',
    margin: '0 auto',
    transition: '.3s',
    '&:hover':{
      backgroundColor: '#47b2e4',
      color: '#fff'
    },
  },
  image: {
    display: 'block',
    width: '150px',
    height: '150px',
    marginLeft: '2rem'
  },
  title: {
    fontWeight: 'bold',
    color: '#37517e',
  },
});

export default function MediaCard({image, title, subtitle, caption, path}) {
  const classes = useStyles();

  return (
    <Box 
      minHeight='400px'
      bgcolor='#fff'
      m={2}
      display='flex' 
      flexDirection='column' 
      justifyContent='center'
      borderRadius= '30px'
      boxShadow='0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
    >
      <Box display='flex' justifyContent='flex-start' pl='1rem'>
        <img src={process.env.PUBLIC_URL + image} className={classes.image} />
      </Box>
        <CardContent style={{paddingTop: '0'}}>
          <Typography className={classes.title} variant="h5" component="h2">
            {title}
          </Typography>
          <Typography gutterBottom variant='h6'>{subtitle}</Typography>
          <Typography gutterBottom variant="body2" color="textSecondary" component="p">
            {caption}
          </Typography>
        </CardContent>
        <Button className={classes.button} size="medium" component={Link} to={path}>
          Leer más
        </Button>
    </Box>
  );
}
