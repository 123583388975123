import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import Navbar from './Navbar'
import Home from './Home'
import Modulos from './Modulos'
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Footer from './Footer'
import {animateScroll as scroll} from 'react-scroll'



const Page = () => {
  const location = useLocation()
  const {hash} = useLocation()
  useEffect(()=>{
    if(hash === '#inicio'){
      window.scrollTo(0,0)
    } else if(hash === '#nosotros'){
      window.scrollTo(0,document.getElementById('about').offsetTop-60)
    } else if(hash === '#modulos'){
      window.scrollTo(0, document.getElementById('modules').offsetTop-60)
    } else if(hash === '#funciones'){
      window.scrollTo(0, document.getElementById('services').offsetTop-60)
    } else if(hash === '#contacto'){
      window.scrollTo(0, document.getElementById('contact').offsetTop-60)    
    }
  }, [hash])
 
  return (  
    <>
    <Navbar/>
    <Home/>
    <About/>
    <Modulos/>
    <Services/>
    <Contact/>
    <Footer/>
    </>
  );
}
 
export default Page;