import React, { Fragment, useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom'
import {animateScroll as scroll} from 'react-scroll'
import {
  Typography,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
  CssBaseline
} from '@material-ui/core'
import DrawerComponent from './DrawerComponent'

const useStyles = makeStyles(theme => ({
  appbarActive: {
    backgroundColor: '#425e8fe6',
    padding: '0rem 0',
    transition: 'all 0.3s ease'
  },
  appbar: {
    backgroundColor: 'transparent',
  },
  toolbar: {
    padding: '0 6rem 0 6rem',
  },
  toolbarCollapse: {
    padding: '0 1rem',
  },
  indicator:{
    marginTop: '20px',
    backgroundColor: '#47b2e4',
    paddingTop: '0.2rem',
  },
  navTabs: {
    marginRight: '15px',
    marginLeft: 'auto',
    minHeight: '64px',
    color: '#fff',
  },
  navTab: {
    marginTop: '10px',
    minWidth: '90px',
    marginRight: '10px',
    fontSize: '15px',
    transition: '0.3s',
    '&:hover': {
      color: '#47b2e4'
    }
  },
  navTabActive: {
    marginTop: '10px',
    minWidth: '90px',
    marginRight: '10px',
    fontSize: '15px',
    transition: '0.3s',
    '&:hover': {
      color: '#47b2e4'
    }
  }
}));

const Navbar = ({inModule, inPrivacity}) => {
  const location = useLocation()
  const classes = useStyles();
  const [value, setValue] = useState(0)
  const handleClickTab = (newValue) => {
    setValue(newValue);
  }

  useEffect(() => {
    if (inModule){
      setValue(2)
    }
    if (inPrivacity){
      setValue(null)
    }
  }, [location.pathname])

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('sm'));
  const [navbar, setNavbar] = useState(false);

  let scrolling = false;
  const changeNavtab=()=>{
    scrolling = true
  }

  setInterval(() => {
    if (scrolling) {
        if(window.scrollY > 0){
          if(!navbar){
            setNavbar(true)
          }
        } else{
          setNavbar(false)
        }
        if(inPrivacity){
          setValue(null)
          return;
        }
        if (inModule || document.getElementById('about') === null){
          if(value!==2) setValue(2)
        } else if (window.scrollY < document.getElementById('about').offsetTop-60){
          if(value!==0) setValue(0)
        } else if(window.scrollY >= document.getElementById('about').offsetTop-60 && window.scrollY < document.getElementById('modules').offsetTop-60){
          if(value!==1) setValue(1)
        } else if(window.scrollY >= document.getElementById('modules').offsetTop-60 && window.scrollY < document.getElementById('services').offsetTop-60){
          if(value!==2) setValue(2)
        } else if(window.scrollY >= document.getElementById('services').offsetTop-60 && window.scrollY < document.getElementById('contact').offsetTop-60){
          if(value!==3) setValue(3)
        } else if(window.scrollY >= document.getElementById('contact').offsetTop-60){
          if(value!==4) setValue(4)
        }
        scrolling = false;
    }
  },300);

  window.addEventListener('scroll', changeNavtab)

  return (
    <Fragment>
      <AppBar className={navbar || inPrivacity ? classes.appbarActive:classes.appbar } elevation={0} style={{marginBottom: `${isMatch?'3rem':''}`}}>
        <Toolbar className={isMatch ? classes.toolbarCollapse : classes.toolbar}>
          <Typography variant='h3' color={'secondary'}>
            Trii
          </Typography>
          {isMatch ? <DrawerComponent location={location.pathname} /> :
            <>
              <Tabs
                onChange={handleClickTab}
                classes={{
                  indicator: classes.indicator,
                }}
                value={value}
                className={classes.navTabs}
              >
                <Tab
                  onClick={()=>scroll.scrollTo(0)}
                  href='/#inicio'
                  className={classes.navTab}
                  label='Inicio'
                >
                </Tab>
                <Tab
                  onClick={()=>scroll.scrollTo(document.getElementById('about').offsetTop-60)}
                  href='/#nosotros'
                  className={classes.navTab}
                  label='Nosotros'
                >    
                </Tab>
                <Tab
                  onClick={()=>scroll.scrollTo(document.getElementById('modules').offsetTop-60)}
                  href='/#modulos'
                  className={classes.navTab}
                  label='Modulos'
                >
                </Tab>
                <Tab
                  onClick={()=>scroll.scrollTo(document.getElementById('services').offsetTop-60)}
                  href='/#funciones'
                  className={classes.navTab}
                  label='Funciones'
                >
                </Tab>
                <Tab
                  href='/#contacto'
                  onClick={()=>scroll.scrollTo(document.getElementById('contact').offsetTop-60)}
                  className={classes.navTab}
                  label='Contacto'
                >
                </Tab>
              </Tabs>
            </>
          }
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

export default Navbar;