import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles'
import {Link} from 'react-router-dom'
import { 
  CssBaseline, 
  Typography,
  Collapse,
  Grid,
  Box,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
  root: {
    background: `linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url(${process.env.PUBLIC_URL + '/img/homeBackground.png'}) fixed center center`,
    backgroundSize: 'cover',
  },
  image: {
    display: 'block',
    width: 'auto',
    height: '150px'
  },
  title: {
    color: '#000',
    fontWeight: 'bold',
  },
  button:{
    marginTop: '1rem',
    marginBottom: '1rem',
    borderRadius: '6px',
    border: '2px solid #47b2e4',
    textDecoration: 'none',
    color: '#47b2e4',
    width: '8rem',
    margin: '0 auto',
    transition: '.3s',
    '&:hover':{
      backgroundColor: '#47b2e4',
      color: '#fff'
    },
    caption: {
      color: '#848484',
      fontSize: '20px',
    },
  }
}))


const Service = ({image, caption, content}) => {
  const classes = useStyles();
  const [isDashboard, setIsDashboard] = useState(()=>{
    if (caption === 'Dashboard'){
      return true
    }
    return false
  })
  return (  
    <Box display='flex' flexDirection='column' pt={3}>
      <Box display='flex' justifyContent='center'>
        <img className={classes.image} src={process.env.PUBLIC_URL + image}/>
      </Box>
      <Box 
        display='flex' 
        justifyContent='center' 
        m={2} 
        flexDirection='column' 
        textAlign='center'
      >
        <Typography className={classes.title} variant="h5" component="h2" >
          {caption}
        </Typography>
        <Typography className={classes.caption}>
          {content}
        </Typography>
        {isDashboard?
        <>
          <Button className={classes.button} size="medium" component={Link} to='/dashboard'>
            Leer más
          </Button>
        </>
        :null}
      </Box>
    </Box>
  );
}
 
export default Service;