import React, {useEffect} from 'react';
import ScrollToTop from './ScrollToTop';
import {makeStyles} from '@material-ui/core'
import {
  Grid, 
  Box, 
  Typography
} from '@material-ui/core'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import MicIcon from '@material-ui/icons/Mic';


import Navbar from './Navbar'
import HeaderModulo from './HeaderModulo'
import Footer from './Footer'


const useStyles = makeStyles(theme=>({
  jumbotronText: {
    padding: '5px 3px 2px 3px',
    textAlign: 'left',
    fontSize: '16px',
    color: '#848484',
  },
  image: {
    display: 'block', 
    maxHeight: '20rem',
    maxWidth: '100%',
  },
  jumbotronTitle: {
    textAlign: 'left',
    fontSize: '34px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '10px',
    color: '#37517e',
  },
  divider: {
    width: '5rem',
    height: '2px',
    backgroundColor: '#47b2e4',
    margin: 'auto',
    marginBottom: '2rem',
  },
}))


const TriiMarketing = () => {
  document.title = 'Trii Marketing'

  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (  
    <>
      <ScrollToTop/>
      <Navbar inModule={true}/>
      <HeaderModulo
        titulo='trii marketing'
        contenido={`
          Una herramienta de envío
          masivo para afianzar las 
          relaciones de la empresa 
          con sus clientes y fortalecer 
          el marketing a traves de las 
          redes sociales.
        `}
      />
      <Box width='100%' display='flex' justifyContent='center' bgcolor='#f3f5fa'>
        <Box width={{sm: '80%', xs:'80%', md:'50%'}}>
          <Grid container nowrap>
            <Grid item xs={12} sm={12} md={6}>
              <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' p={2}>
                <img src={process.env.PUBLIC_URL + '/img/sobres.webp'} />
                <Box width='60%'>
                  <Typography className={classes.jumbotronText}>Podrás realizar envíos masivos a todos tus clientes mediante mensajes personalizados.</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box height='100%' display='flex' flexDirection='column' p={2} alignItems='center' justifyContent='center'>
                <Typography className={classes.jumbotronText}>Envía textos, audios o imágenes</Typography>
                <Box display='flex' justifyContent='center'>
                  <Box 
                    m={1} 
                    borderRadius='50%' 
                    p='4px' 
                    bgcolor='#25D366'
                    display='flex' 
                    justifyContent='center'
                    boxShadow='0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
                  >
                    <WhatsAppIcon style={{color: '#fff'}}/>
                  </Box>
                  <Box 
                    m={1} 
                    borderRadius='50%' 
                    p='4px' 
                    bgcolor='#02a7e1' 
                    display='flex' 
                    justifyContent='center'
                    boxShadow='0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
                  >
                    <EmailIcon style={{color: '#fff'}}/>
                  </Box>
                  <Box 
                    m={1} 
                    border='1px solid #9707a1'
                    borderRadius='50%' 
                    p='4px' 
                    bgcolor='#fff' 
                    display='flex' 
                    justifyContent='center'
                    boxShadow='0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'  
                  >
                    <MicIcon style={{color: '#9707a1'}}/>
                  </Box>
                </Box>
                <Box pt={2}>
                  <Typography className={classes.jumbotronText}><strong>Canales compatibles:</strong></Typography>
                  <Box display='flex'>
                    <DoneOutlineIcon style={{color: '#47b2e4'}}/>
                    <Typography className={classes.jumbotronText}>WhatsApp</Typography>
                  </Box>
                  <Box display='flex'>
                    <DoneOutlineIcon style={{color: '#47b2e4'}}/>
                    <Typography className={classes.jumbotronText}>Email</Typography>
                  </Box>
                  <Box display='flex'>
                    <DoneOutlineIcon style={{color: '#47b2e4'}}/>
                    <Typography className={classes.jumbotronText}>Canales de Audio</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container nowrap>
            <Grid item xs={12} sm={12} md={6}>
              <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' p={2}>
                <Box py={2}>
                  <Typography className={classes.jumbotronText}>Podrás generar nuevas ventas o simplemente comunicar información de utilidad para tus clientes.</Typography>
                  <img src={process.env.PUBLIC_URL + '/img/mensaje1Marketing.webp'} />
                </Box>
                <Box>
                  <Typography className={classes.jumbotronText}>A través de los distintos canales podrás enviar, desde recordatorios hasta promociones.</Typography>
                  <img src={process.env.PUBLIC_URL + '/img/mensaje2Marketing.webp'} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box height='100%' display='flex' flexDirection='column' pt={1} alignItems='center' justifyContent='center'>
                <img src={process.env.PUBLIC_URL + '/img/reportesMarketing.webp'}/>
                <Typography className={classes.jumbotronText}>Recibe reportes de tus campañas</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
 
export default TriiMarketing;